<template>
	<article class="space-y-16 tabletWide:space-y-24" :scroll-target="id">
		<label :for="id" class="flex flex-wrap max-w-824 tablet:pl-16 tabletWide:p-0 tabletWide:pointer-events-none">
			<input type="checkbox" :name="id" :id="id" class="peer hidden">
			<svg-icon name="add"      class="iconSize-24 opacity-30 text-goBlue mr-8 tabletWide:mt-4 peer-checked:text-goPink tabletWide:peer-checked:hidden tabletWide:hidden peer-checked:hidden block" /> 
			<svg-icon name="subtract" class="iconSize-24 opacity-30 text-goBlue mr-8 tabletWide:mt-4 peer-checked:text-goPink tabletWide:peer-checked:hidden tabletWide:hidden peer-checked:block hidden" /> 
			<header class="flex-1 text-goBlue peer-checked:text-goPink tabletWide:pl-32"> 
				<p class="font-bold text-20 tabletWide:text-24">
					<slot name="question"/>
				</p>
			</header> 
			<p class="w-full pl-32 mt-16 tabletWide:mt-24 tabletWide:block tabletWide:pointer-events-auto peer-checked:block hidden"> 
				<slot name="answer"/>
			 </p>
		</label>
		<hr class="border-black/10">
	</article>
</template>

<script>
export default {
	props: {
		id: {
			type: String,
			required: true
		},
	},
}
</script>
