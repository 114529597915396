<template>
	<div class="space-y-32">
		<header class="tablet:pl-48 tabletWide:p-0">
			<h2 class="font-bold text-20 tablet:text-24">Frequently asked questions</h2>
		</header>

		<div v-once class="space-y-16 tablet:space-y-24">
			<faqAccordion v-for="faq in faqs" :id="faq.id" :key="faq.id">
				<template #question>
					{{ faq.question }}
				</template>
				<template #answer>
					<span v-html="faq.answer" />
				</template>
			</faqAccordion>
		</div>

		<div class="tablet:pl-48 tabletWide:p-0">
			<nuxt-link :to="readMoreLink" class="button button-goBlueGhost button-normal">
				Read more FAQs
			</nuxt-link>
		</div>
	</div>
</template>

<script>
import faqAccordion from '~/components/faqAccordion.vue'
export default {
	components: {
		faqAccordion,
	},
	props: {
		faqs: {
			type: Array,
			required: true
		},
		readMoreLink: {
			type: [String, Object],
			default: () => ({ name: 'help' })
		},
	},
	data() {
		return {
			timeout: 300
		}
	},
	computed: {
		hashFaqId() {
			return this.$route?.hash ? this.$route.hash.substring(1) : null
		}
	},
	watch: {
		$route() {
			this.scrollToFaq()
		}
	},
	mounted() {
		this.scrollToFaq()
	},
	methods: {
		scrollToFaq() {
			if (!this.hashFaqId) return

			const allIds = this.faqs.map(faq => faq.id)
			if (!allIds.includes(this.hashFaqId)) return

			document.querySelector(`[id="${this.hashFaqId}"]`).checked = true
			// using setTimeout to wait the element to be rendered
			// seems to be the only way to make it work in ios for all scenarios
			setTimeout(() => {
				this.$scrollTo(this.hashFaqId)
				this.timeout = 0
			}, this.timeout)
		},
	}
}
</script>
